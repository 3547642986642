
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fortgeh2-primary: mat.define-palette(mat.$indigo-palette);
$fortgeh2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fortgeh2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fortgeh2-theme: mat.define-light-theme((
  color: (
    primary: $fortgeh2-primary,
    accent: $fortgeh2-accent,
    warn: $fortgeh2-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fortgeh2-theme);
/* You can add global styles to this file, and also import other style files */
html, body {
    color: #31313a;
}

:root {
    --main-color: #F24B69;
}

::selection {
    background: var(--main-color);
    color: #fff;
}

.cc-revoke {
    display: none !important;
}

.sprd-startpage-promotion__caption,
.sprd__caption, .sprd-header__title,
.sprd__headline {
  font-family: "Pacifico" !important;
}
.sprd-startpage-promotion__center, .sprd-btn-primary {
  color: white !important;
  > button {
    color: white !important;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/pacifico/FwZY7-Qmy14u9lezJ-6K6MmTpA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/pacifico/FwZY7-Qmy14u9lezJ-6D6MmTpA.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/pacifico/FwZY7-Qmy14u9lezJ-6I6MmTpA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/pacifico/FwZY7-Qmy14u9lezJ-6J6MmTpA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/pacifico/FwZY7-Qmy14u9lezJ-6H6Mk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/material-icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}